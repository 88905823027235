import React, { useEffect, useState } from 'react'
// import PropTypes from 'prop-types'

import { capitalizeFirst } from '../utilities' //	db, , singular
import { store } from './utilities'
import { FULL_TEXT_SEARCH_URL } from '../config'
// import moment from 'moment'

import { Input, Icon, Button, DatePicker } from 'antd'
import DB from '../DAL/DB'

const Users = new DB.Users()

/* these functions return objects that, when spread over a column in a table defintion, add filtering to that column */

export const textSorter = (a, b) => {
  if (a && b) {
    if (a.toLowerCase() === b.toLowerCase()) return 0
    else return a.toLowerCase() > b.toLowerCase() ? 1 : -1
  } else if (!a) return -1
  else if (!b) return 1
  else return 0
}

export const numberSorter = (a, b) => {
  if (a && b) {
    if (a === b) return 0
    else return a > b ? 1 : -1
  } else if (!a) return -1
  else if (!b) return 1
  else return 0
}

export const textFilter = (activeFilters) => (category) => ({
  filteredValue: activeFilters.filters ? activeFilters.filters[category] : [],
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ border: `0.1rem solid lightgrey`, borderRadius: `0.5rem`, padding: `2rem`, backgroundColor: `white` }}>
      <Input
        placeholder={`Search ${capitalizeFirst(category)}`}
        value={selectedKeys[0]}
        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={confirm}
      />
      <div style={{ display: `flex`, flexFlow: `row nowrap`, margin: `1rem 0 0 0` }}>
        <Button icon="search" size="small" onClick={confirm}>
          Search
        </Button>
        <Button size="small" onClick={clearFilters}>
          Reset
        </Button>
      </div>
    </div>
  ),
  filterIcon: (filtered) => <Icon type="search" />,
  render: (text) => <div>{text}</div>,
})

export const dateFilter = (activeFilters) => (category) => ({
  filteredValue: activeFilters.filters || [],
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
    if (selectedKeys[2] === `submitted`) {
      setSelectedKeys([])
    }
    return (
      <div
        style={{
          border: `0.1rem solid lightgrey`,
          borderRadius: `0.5rem`,
          padding: `2rem`,
          backgroundColor: `white`,
          display: `flex`,
          flexFlow: `column`,
        }}
      >
        <DatePicker
          placeholder="From"
          value={selectedKeys[0]}
          onChange={(e) => {
            const v = Array.from(selectedKeys)
            v[0] = e ? e.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }) : undefined
            setSelectedKeys(v)
          }}
        />
        <DatePicker
          placeholder="To"
          value={selectedKeys[1]}
          onChange={(e) => {
            const v = Array.from(selectedKeys)
            v[1] = e ? e.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).date(e.date() + 1) : undefined
            setSelectedKeys(v)
          }}
        />
        <div style={{ display: `flex`, flexFlow: `row nowrap`, margin: `1rem 0 0 0` }}>
          <Button
            icon="search"
            size="small"
            onClick={(e) => {
              confirm()
              selectedKeys[2] = `submitted`
              setSelectedKeys(selectedKeys)
            }}
          >
            Search
          </Button>
          <Button size="small" onClick={clearFilters}>
            Reset
          </Button>
        </div>
      </div>
    )
  },
})

const ClientSelect = ({ onSelect }) => {
  const [clients, setClients] = useState([])

  const getClients = async () => {
    const state = store.getState()
    const { or = () => [], permissions = () => [] } = state.tableVars.tableStrategy || {}
    const p = permissions() || []


    const body = {
      type: state.tableVars.tableStrategy.type,
      sortField: `client.id.sortable`,
      filters: p,
      or: or(),
    }

    body.permissions = [...body.filters]
    delete body.filters

    const url = `${FULL_TEXT_SEARCH_URL}/counts`
    const res = await fetch(url, {
      method: `POST`,
      mode: `cors`,
      headers: { 'Content-Type': `application/json` },
      body: JSON.stringify(body),
    })
    const counts = await res.json()

    const ids = (counts.aggregations?.aggCounts?.buckets || []).map(({ key }) => (key))

    const users = await Promise.all(ids.map((id) => {
      return Users.get(id)
    }))

    return users.map(({ data }) => (data[0]))
  }

  useEffect(async () => {
    const clients = await getClients()
    setClients(clients)
    console.info('Clients loaded:', clients)
  }, [])
  return (
    <div>
      <select
        name="Client"
        id="clients"
        onChange={(e) => {
          onSelect(e.target.value)
        }}
      >
        <option value="">All</option>
        {clients.map((client) => {
          if (!client) return null
          return (
            <option value={client.id} key={client.id}>
              {client.name}
            </option>
          )
        })}
      </select>
    </div>
  )
}

export const clientFilter = (activeFilters) => (category) => ({
  filteredValue: activeFilters.filters || [],
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, ...filterProps }) => {
    if (selectedKeys[2] === `submitted`) {
      setSelectedKeys([])
    }
    return (
      <ClientSelect
        onSelect={(id) => {
          console.info('Client Selected:', id)
          setSelectedKeys([id])
          confirm()
        }}
      />
    )
  },
})

const InspectorSelect = ({ onSelect }) => {
  const [users, setUsers] = useState([])


  const getInspectors = async () => {
    const state = store.getState()
    const { or = () => [], permissions = () => [] } = state.tableVars.tableStrategy || {}
    const p = permissions() || []
    // p.push({match: {'organization.id': auth.sunkaizenUser.organization.id}})

    const body = {
      type: state.tableVars.tableStrategy.type,
      sortField: `inspection.inspector.id.sortable`,
      filters: p,
      or: or(),
    }

    body.permissions = [...body.filters]
    delete body.filters

    const url = `${FULL_TEXT_SEARCH_URL}/counts`

    console.log('url inspector',url,body)

    const res = await fetch(url, {
      method: `POST`,
      mode: `cors`,
      headers: { 'Content-Type': `application/json` },
      body: JSON.stringify(body),
    })
    const counts = await res.json()
    console.info('Inspectors loaded:', counts)

    const ids = (counts.aggregations?.aggCounts?.buckets || []).map(({ key }) => (key))

    console.log('ids',ids)

    let users = await Promise.all(ids.map((id) => {
      return Users.get(id)
    }))

    console.log('users',users)

    users = users.filter(user => user.ok)

    console.log('users after',users)

    return users.map(({ data }) => (data[0]))
  }

  useEffect(async () => {
    const users = await getInspectors()
    console.info('Inspectors loaded:', users)
    setUsers(users)
  }, [])

  return (
    <div>
      <select
        name="Inspector"
        id="inspectors"
        onChange={(e) => {
          onSelect(e.target.value)
        }}
      >
        <option value="">All</option>
        {users && users.map((user) => {
          return (
            <option value={user.id} key={user.id}>
              {user.name}
            </option>
          )
        })}
      </select>
    </div>
  )
}

export const inspectorFilter = (activeFilters) => (category) => ({
  filteredValue: activeFilters.filters || [],
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
    if (selectedKeys[2] === `submitted`) {
      setSelectedKeys([])
    }
    return (
      <InspectorSelect
        onSelect={(id) => {
          console.info('Inspector Selected:', id)
          setSelectedKeys([id])
          confirm()
        }}
      />
    )
  },
})

