import React, { useEffect, useRef } from 'react';
import { Button, message } from 'antd';
import { Sticky } from 'react-sticky';
import { history } from '../history';
import { saveIntake } from './utilities';
import { connect } from 'react-redux';

export function TabBar({ project, highlightErrors, props, Default }) {
  const projectIdRef = useRef(project.id); // Reference to store the current project ID
  const intervalRef = useRef(null); // Ref to store the interval ID

  const preserveScrollPosition = (callback) => {
    const scrollPosition = window.pageYOffset;
    callback();
    window.scrollTo(0, scrollPosition);
  };
  
  async function saver(route = null) {
    try {
      message.loading({ content: 'Auto-saving data...', key: 'saving', duration: 1 });
  
      if (project?.id === projectIdRef.current) { // Only save if the current project matches
        preserveScrollPosition(async () => {
          await saveIntake(); // Trigger the save operation
        });
      }
  
      message.success({ content: 'Data saved successfully!', key: 'saving', duration: 2 });
  
      if (route) {
        history.push(route);
      }
    } catch (e) {
      highlightErrors(e);
      message.error({ content: 'Error saving data.', key: 'saving', duration: 2 });
      console.log('Error during auto-save', e);
    }
  }
  
  useEffect(() => {
    projectIdRef.current = project.id; // Update the current project ID reference on project change
  
    // Clear any existing interval for previous projects
    clearInterval(intervalRef.current);
    
    // Set a new interval for the current project
    intervalRef.current = setInterval(() => {
      if (project?.id === projectIdRef.current) {
        saver();
      }
    }, 1200000); // 30 minutes interval
  
    return () => clearInterval(intervalRef.current); // Clear interval on unmount or project change
  }, [project.id]); // Reset interval whenever the project ID changes

  return (
    <Sticky>
      {({ style }) => (
        <div className="flex-row background-white justify-space-between" style={{ ...style, zIndex: 10 }}>
          <Default {...props} style={{ width: '100%' }} />
          <div className="flex-row" style={{ display: 'none' }}>
            <Button style={{ margin: `1rem 1rem 1rem 0` }} type="default" onClick={() => saver()}>
              Save
            </Button>
            <Button
              style={{ margin: `1rem 0 1rem 0` }}
              type="primary"
              onClick={() => saver(`/projects/${project.id}/manage`)}
            >
              Save and Exit
            </Button>
          </div>
        </div>
      )}
    </Sticky>
  );
}

export default connect()(TabBar);
